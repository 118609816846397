export const testimonialData = [
  {
    name: "Cindy",
    review:
      "\" I had a really positive experience with (Ashwin and Zeb). Their team was attentive and knowledgeable, and they provided solutions that exceeded my expectations.The process was smooth, and I'm happy with the results. I'd recommend them to anyone looking for services. \"",
  },
  {
    name: "Jimmy",
    review:
      '" Got lucky with an intro for our podcast when we came across Ashwin’s and Zeb\'s work! Not only was their work clean, they also knew what we wanted and were able to give us clarity on what would go well for us! And it did 😁 "',
  },
];
