

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface ApiRequestOptions extends Omit<RequestInit, 'method' | 'body'> {
  method?: HttpMethod;
  data?: object;
}

export interface ApiResponse<T> {
  data: T | null;
  error: string | null;
}

export async function fetchData<T>(
  endpoint: string, 
  options: ApiRequestOptions = {}
): Promise<ApiResponse<T>> {
  const url = `${BASE_URL}${endpoint}`;
  const { method = 'GET', data, ...fetchOptions } = options;

  console.log(`Attempting to ${method} data to/from: ${url}`);
  
  const headers = {
    'Content-Type': 'application/json',
    ...fetchOptions.headers,
  };

  const config: RequestInit = {
    method,
    ...fetchOptions,
    headers,
  };

  if (data) {
    config.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(url, config);
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    // Check if the response is JSON
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const data = await response.json();
      return { data, error: null };
    } else {
      const data = await response.text() as T;
      return { data, error: null };
    }
  } catch (error) {
    console.error('Fetch error:', error);
    return { 
      data: null, 
      error: error instanceof Error ? error.message : 'An unknown error occurred'
    };
  }
}