import { Carousel } from "react-bootstrap";
import "./testimonial.scss";
import TestimonialCard from "../../components/cards/testimonialCard";
import { testimonialData } from "../../utils/data";

const Testimonials = ({
  testimonialScrollRef,
}: {
  testimonialScrollRef: React.MutableRefObject<null>;
}) => {
  const settings = {
    dots: true,
    infinite: true,

    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section
      className="sectiontest"
      id="testimonials"
      ref={testimonialScrollRef}
    >
      <h1 className="title">Our Clients Says</h1>
      <Carousel controls={false}>
        {testimonialData.map((data) => (
          <Carousel.Item>
            <div className="cardtest">
              <h2 className="cardtest-title">{data.name}</h2>
              <p className="cardtest-description">{data.review}</p>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
};

export default Testimonials;
