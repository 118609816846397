import React from "react";
import "./homebody.scss"; // Assuming you have some styles for Homebody
import VFX from "../../assets/images/vfx.jpg";
import CGI from "../../assets/images/cgi.png";
import MotionGraphics from "../../assets/images/motion-graphics.jpg";
import GraphicDesign from "../../assets/images/graphic-design.jpg";
import Card from "../../components/cards/cards";

const Homebody = ({
  serviceScrollRef,
}: {
  serviceScrollRef: React.MutableRefObject<null>;
}) => {
  return (
    <>
      <section className="app-card" ref={serviceScrollRef}>
        <h2>Our Services </h2>
        <div className="card-container1">
          <Card
            imageUrl={VFX}
            title="Visual Effects (VFX)"
            description="Creating breath-taking effects that enhance your visual narrative."
          />
          <Card
            imageUrl={CGI}
            title="Computer-Generated Imagery (CGI)"
            description="Crafting lifelike and imaginative visuals with precision."
          />
          <Card
            imageUrl={MotionGraphics}
            title="Motion Graphics"
            description="Designing dynamic animations that engage and captivate your audience."
          />
          <Card
            imageUrl={GraphicDesign}
            title="Graphic Design"
            description="Developing striking designs that communicate your message effectively."
          />
        </div>
      </section>
    </>
  );
};

export default Homebody;
