import React from "react";
import "./video.scss";

const Video = ({
  contactScrollRef,
}: {
  contactScrollRef: React.MutableRefObject<null>;
}) => {
  return (
    <section className="section">
      <iframe
        className="iframe"
        src="https://www.youtube.com/embed/V_xA1kkzBDk?si=Ie7eiJWUNtckDrGa&rel=0;controls=0;"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </section>
  );
};

export default Video;
