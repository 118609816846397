import { Route, Routes } from "react-router-dom";
import Home from "../../pages/Home";

const Body = ({
  aboutUsScrollRef,
  topScrollRef,
  serviceScrollRef,
  contactScrollRef,
  testimonialsScrollRef,
}: {
  aboutUsScrollRef: React.MutableRefObject<null>;
  topScrollRef: React.MutableRefObject<null>;
  serviceScrollRef: React.MutableRefObject<null>;
  contactScrollRef: React.MutableRefObject<null>;
  testimonialsScrollRef: React.MutableRefObject<null>;
}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            aboutUsScrollRef={aboutUsScrollRef}
            topScrollRef={topScrollRef}
            serviceScrollRef={serviceScrollRef}
            contactScrollRef={contactScrollRef}
            testimonialsScrollRef={testimonialsScrollRef}
          />
        }
      />
    </Routes>
  );
};

export default Body;
