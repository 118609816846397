import React, { useEffect, useState } from "react";
import "./Home.scss";
import Hero from "../components/hero/hero";
import { ApiResponse, fetchData } from "../services/apiService";
import baseGif from "../assets/gifs/Ruins_GIF_FORMAT.gif";
import ContactUs from "./contactus/contactus";
import Aboutus from "./aboutus/aboutus";
import Homebody from "./homebody/homebody";
import Testimonials from "./testimonials/testimonial";
import Video from "./video/video";

interface TestData {
  message: string;
}

interface PostData {
  title: string;
  content: string;
}
const Home = ({
  aboutUsScrollRef,
  topScrollRef,
  serviceScrollRef,
  contactScrollRef,
  testimonialsScrollRef,
}: {
  aboutUsScrollRef: React.MutableRefObject<null>;
  topScrollRef: React.MutableRefObject<null>;
  serviceScrollRef: React.MutableRefObject<null>;
  contactScrollRef: React.MutableRefObject<null>;
  testimonialsScrollRef: React.MutableRefObject<null>;
}) => {
  const [getMessage, setGetMessage] = useState<ApiResponse<TestData>>({
    data: null,
    error: null,
  });
  const [postMessage, setPostMessage] = useState<ApiResponse<PostData>>({
    data: null,
    error: null,
  });

  useEffect(() => {
    const fetchTestData = async () => {
      // GET request
      const getResult = await fetchData<TestData>("/test");
      setGetMessage(getResult);

      // POST request
      const postData = { title: "Test Post", content: "This is a test post" };
      const postResult = await fetchData<PostData>("/api/post", {
        method: "POST",
        data: postData,
      });
      setPostMessage(postResult);
    };

    fetchTestData();
  }, []);

  return (
    <div className="home-page" ref={topScrollRef}>
      <Hero
        backgroundImage={baseGif}
        title="Crafting Visual Magic, One Pixel at a Time."
        subtitle=""
        isHome={true}
      />
      <div className="home-content">
        <Aboutus aboutUsScrollRef={aboutUsScrollRef} />
        <Homebody serviceScrollRef={serviceScrollRef} />
        <Video contactScrollRef={contactScrollRef} />
        <ContactUs contactScrollRef={contactScrollRef} />
        <Testimonials testimonialScrollRef={testimonialsScrollRef} />
      </div>
    </div>
  );
};

export default Home;
