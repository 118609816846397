import { useState, useEffect } from "react";
import "./scrolltop.scss";

const ScrollToTopButton = () => {
  const [scrollPosition, setSrollPosition] = useState<number>(0);
  const [showGoTop, setshowGoTop] = useState<string>("goTopHidden");

  const handleVisibleButton = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  });

  useEffect(() => {
    if (scrollPosition > 50) {
      return setshowGoTop("goTop");
    } else if (scrollPosition < 50) {
      return setshowGoTop("goTopHidden");
    }
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button onClick={scrollToTop} className={`${showGoTop} scroll-button`}>
      ↑
    </button>
  );
};

export default ScrollToTopButton;
