import React, { useState, useEffect } from "react";

const Typewriter = ({
  text,
  speed,
  pauseTime,
}: {
  text: string;
  speed: number;
  pauseTime: number;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (!isDeleting && currentIndex < text.length) {
          setDisplayText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else if (isDeleting && currentIndex >= 1) {
          setDisplayText((prevText) => prevText.slice(0, -1));
          setCurrentIndex((prevIndex) => prevIndex - 1);
        }

        if (currentIndex === text.length) {
          setIsDeleting(true);
          setTimeout(() => {
            setIsDeleting(false);
          }, pauseTime);
        }

        if (currentIndex === -1) {
          setCurrentIndex(0);
        }
      },
      isDeleting ? speed / 2 : speed
    );

    return () => clearInterval(interval);
  }, [currentIndex, text, speed, pauseTime, isDeleting]);

  return <>{displayText}</>;
};

export default Typewriter;
