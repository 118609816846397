import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";
import Header from "./components/header/header";
import { useRef } from "react";
import ScrollToTopButton from "./components/scrolltop/scrolltop";

function App() {
  const aboutUsScrollRef = useRef(null);
  const topScrollRef = useRef<any>(null);
  const serviceScrollRef = useRef<any>(null);
  const contactScrollRef = useRef<any>(null);
  const testimonialsScrollRef = useRef<any>(null);
  return (
    <Router>
      <div className="App">
        <Header
          aboutUsScrollRef={aboutUsScrollRef}
          topScrollRef={topScrollRef}
          serviceScrollRef={serviceScrollRef}
          contactScrollRef={contactScrollRef}
          testimonialsScrollRef={testimonialsScrollRef}
        />
        {/* <Hero /> */}
        <main className="App-main">
          <Body
            aboutUsScrollRef={aboutUsScrollRef}
            topScrollRef={topScrollRef}
            serviceScrollRef={serviceScrollRef}
            contactScrollRef={contactScrollRef}
            testimonialsScrollRef={testimonialsScrollRef}
          />
        </main>
        <Footer topScrollRef={topScrollRef} />
        <ScrollToTopButton />
      </div>
    </Router>
  );
}

export default App;
