import React from "react";
import "./aboutus.scss";

const Aboutus = ({
  aboutUsScrollRef,
}: {
  aboutUsScrollRef: React.MutableRefObject<null>;
}) => {
  return (
    <section className="card-container" ref={aboutUsScrollRef}>
      <h2>About Us</h2>
      <div className="p-text">
        Welcome to PinkPumkin Creatives, where imagination meets innovation. We
        are a dynamic team of artists specializing in VFX, CGI, motion graphics,
        and graphic design, dedicated to transforming your creative concepts
        into stunning visual realities.
      </div>
      <div className="p-text">
        While working as artists in the VFX industry, our collective passion for
        visual storytelling has greatly deepened. Engaging in various projects
        across film and other media sectors, we recognized the transformative
        power of visual effects in enhancing narratives and creating immersive
        experiences. This realization inspired us to establish PinkPumkin
        Creatives, where we aim to leverage our expertise and creativity to
        reach a broader audience.
      </div>
      <div className="p-text">
        At PinkPumkin Creatives, we pride ourselves on our attention to detail
        and commitment to excellence. We understand that every project is
        unique, and we tailor our services to meet the specific needs of each
        client. Our goal is to exceed expectations by providing high-quality,
        visually captivating work that tells your story in the most compelling
        way possible.
      </div>
    </section>
  );
};

export default Aboutus;
