import React from "react";
import "./Footer.scss";

const Footer = ({
  topScrollRef,
}: {
  topScrollRef: React.MutableRefObject<null>;
}) => {
  return (
    <footer className="footer">
      {/* <div className="footer-content">
        <div className="footer-signup">
          <h2>NEVER MISS OUT ON A MOMENT WITH Pink Pumpkin.</h2>
          <form className="signup-form">
            <input type="email" placeholder="Email address" />
            <button type="button">SUBSCRIBE</button>
          </form>
        </div>
        <div className="footer-links">
          <div className="footer-section">
            <h3>FLAVORS</h3>
            <ul>
              <li>Jalapeño & Blood Orange</li>
              <li>Ginger</li>
              <li>Grapefruit & Pomelo</li>
              <li>Hibiscus & Pomegranate</li>
              <li>Yuzu & Lime</li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>INFORMATION</h3>
            <ul>
              <li>Terms</li>
              <li>Privacy</li>
              <li>Policy</li>
              <li>FAQs</li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>CONTACT</h3>
            <ul>
              <li>Wholesale</li>
              <li>General</li>
              <li>Instagram</li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="footer-bottom">
        <p>© 2024 Pink Pumpkin. All rights reserved.</p>
        {/* <p>SITE BY MARCD</p> */}
      </div>
    </footer>
  );
};

export default Footer;
