import React, { useState } from "react";
import "./header.scss";
import { Link } from "react-router-dom";
import logoGif from "../../assets/images/FINAL_LOGO_small.png";

const Header = ({
  aboutUsScrollRef,
  topScrollRef,
  serviceScrollRef,
  contactScrollRef,
  testimonialsScrollRef,
}: {
  aboutUsScrollRef: React.MutableRefObject<any>;
  topScrollRef: React.MutableRefObject<any>;
  serviceScrollRef: React.MutableRefObject<any>;
  contactScrollRef: React.MutableRefObject<any>;
  testimonialsScrollRef: React.MutableRefObject<any>;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="App-header">
      <nav className="navbar1">
        <div className="navbar1-container">
          <div className="d-flex align-center">
            <Link to="/" className="navbar1-logo">
              <img src={logoGif} alt="Pink Pumpkin" className="logo-image" />
            </Link>
            <div className="flex-container row">
              <h6 className="logo-text">
                <span className="pink-text">Pink</span> Pumpkin
              </h6>
              <h6 className="logo-text">Creatives</h6>
            </div>
          </div>

          <div className={`navbar1-links ${isOpen ? "open" : ""}`}>
            <ul>
              {/* <li>
                <button
                  onClick={() =>
                    !isOpen &&
                    topScrollRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                >
                  Home
                </button>
              </li> */}
              <li>
                <button
                  onClick={() =>
                    !isOpen &&
                    aboutUsScrollRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                >
                  About Us
                </button>
              </li>
              <li>
                <button
                  onClick={() =>
                    !isOpen &&
                    serviceScrollRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                >
                  Our Services
                </button>
              </li>
              <li>
                <button
                  onClick={() =>
                    !isOpen &&
                    testimonialsScrollRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                >
                  Testimonials
                </button>
              </li>
              <li>
                <button
                  // className="navbar1-phone"
                  onClick={() =>
                    !isOpen &&
                    contactScrollRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                >
                  Work with us
                </button>
                {/* <a className="navbar1-phone" href="" target="_blank">
                  Work with us
                </a> */}
              </li>
            </ul>
          </div>
          {/* <div id="mobile" onClick={toggleNavbar}>
            <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
          </div> */}
        </div>
      </nav>
    </header>
  );
};

export default Header;
